#main {
  background-color: #282c34;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

img {
  display: none;
}

.controls {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input {
  display: none;
}

button {
  z-index: 9999;
  padding: 5px;
}

#result {
  margin-top: 10px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  background-color: black;
  opacity: 0.6;
  z-index: 9999;
  padding: 5px;
  max-width: 90%;
  text-align: center;
}

#result:empty {
  display:none;
}
